import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import CompositionFilterFlat from "components/Content/AdvancedFilters/AdvancedFiltersFlat/CompositionFilterFlat";

interface CompositionFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const CompositionFilter = ({
  setSearchFilters,
  searchFilters,
}: CompositionFilterProps) => {
  const reset = () => () => {};
  const info = (
    <p>
      COMPOSITION REFERS TO THE WAY ELEMENTS OF A SCENE ARE ARRANGED IN A CAMERA
      FRAME. SHOT COMPOSITION REFERS TO THE ARRANGEMENT OF VISUAL ELEMENTS TO
      CONVEY AN INTENDED MESSAGE.
    </p>
  );
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      title="COMPOSITION"
      isComingSoon
    >
      <CompositionFilterFlat />
    </AdvancedFilterWrapper>
  );
};

export default CompositionFilter;
