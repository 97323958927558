const AspectRationFilterFlat = () => (
  <svg
    width="325"
    height="130"
    viewBox="0 0 325 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.16211"
      y="68.5573"
      width="78.7203"
      height="60.73"
      stroke="white"
      strokeWidth="0.75"
    />
    <rect
      x="1.16211"
      y="54.6373"
      width="101.755"
      height="74.65"
      stroke="white"
      strokeWidth="0.75"
    />
    <rect
      x="1.16211"
      y="82.4773"
      width="47.6232"
      height="46.81"
      stroke="white"
      strokeWidth="0.75"
    />
    <rect
      x="1.16211"
      y="39.5573"
      width="134.004"
      height="89.73"
      stroke="white"
      strokeWidth="0.75"
    />
    <rect
      x="1.16211"
      y="10.5573"
      width="221.537"
      height="118.73"
      stroke="white"
      strokeWidth="0.75"
    />
    <rect
      x="1.16211"
      y="1.27734"
      width="322.89"
      height="128.01"
      stroke="white"
      strokeWidth="0.75"
    />
    <rect
      x="1.16211"
      y="24.4766"
      width="186.984"
      height="104.81"
      stroke="#EE0000"
      strokeWidth="0.75"
    />
  </svg>
);

export default AspectRationFilterFlat;
