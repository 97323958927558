import LogoRefractio from "icons/LogoRefractio";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClientNameFromSubdomain } from "utils";
import { logoutUser } from "authentication/authService";

export const TopLine = () => {
  const [subdomain, setSubdomain] = useState(String);
  const [hasClient, setHasClient] = useState(false);
  const [clientLogo, setClientLogo] = useState(null);

  useEffect(() => {
    const loadClientLogo = async () => {
      try {
        const logoModule = await import(
          `icons/${subdomain}_logo.png`
          );
          setClientLogo(logoModule.default);
        } catch (error) {
          console.error("Failed to load client logo:", error);
        }
      }
      const subdomain = getClientNameFromSubdomain();
      setSubdomain(subdomain);
      console.log("subdomain:", subdomain);
      if (subdomain != '' && subdomain != 'localhost' && subdomain != 'staging' && subdomain != 'app' && subdomain != 'app2') {
        setHasClient(true);
        loadClientLogo();
      }
  }, []);

  const navigate = useNavigate();

  const handleLogout = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    console.log(666);
    event.preventDefault();
    logoutUser();
    navigate("/login");
  };

  return (
    <div className="h-[62px] flex justify-between items-center border-b border-white w-full bg-black px-6">
      <button
        className="flex justify-between items-center gap-3 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <LogoRefractio className="w-[116px] h-[56px]" />
        { hasClient && <p className="text-[35px]">×</p>}
        { hasClient && <img src={clientLogo!} alt="Logo" className="h-[30px]" />}
      </button>
      <button onClick={handleLogout} className="text-base text-[#848484]">Log out</button>
    </div>
  );
};
