import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import TimeOfDayFilterFlat from "components/Content/AdvancedFilters/AdvancedFiltersFlat/TimeOfDayFilterFlat";

interface TimeOfDayFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const TimeOfDayFilter = ({
  setSearchFilters,
  searchFilters,
}: TimeOfDayFilterProps) => {
  const reset = () => () => {};
  const info = (
    <p>
      THIS FILTER DIVIDE A DAY INTO FOUR DIFFERENT PARTS, WHICH DON’T TELL US
      THE EXACT TIME; INSTEAD, THEY REFER TO AN INTERVAL OF THE DAY
    </p>
  );
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      title="TIME OF THE DAY"
      isComingSoon
    >
      <TimeOfDayFilterFlat />
    </AdvancedFilterWrapper>
  );
};

export default TimeOfDayFilter;
