import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import { useContext } from "react";
import { ThemeContext } from "theme";

interface DialogFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const DialogFilter = ({
  setSearchFilters,
  searchFilters,
}: DialogFilterProps) => {
  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        dialog: { value: e.target.value, isEnabled: !!e.target.value },
      },
    });
  };

  const reset = () => {
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        dialog: { isEnabled: false, value: "" },
      },
    });
  };
  const info = (
    <p>
      A CINEMATIC DIALOGUE IS ORAL SPEECH BETWEEN TWO OR MORE FICTIONAL
      CHARACTERS
    </p>
  );
  const { mainColor } = useContext(ThemeContext);
  return (
    <AdvancedFilterWrapper reset={reset} info={info} title="DIALOG">
      <input
        style={{ color: mainColor }}
        className="text-xl bg-black outline-none w-[250px]"
        placeholder="_Type your dialogue here_"
        onChange={onSearchTextChange}
        value={searchFilters.advancedFilters.dialog.value}
      />
    </AdvancedFilterWrapper>
  );
};

export default DialogFilter;
